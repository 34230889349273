/*
 * @Author: dingguowei
 * @Date: 2023-05-18 11:26:03
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-05-29 16:12:28
 */
import request from '@/plugins/axios'
const base = '/'

export function getMxActivity(params) {
  return request({
    url: base + 'api/jdActivity/mx/activity/page',
    method: 'get',
    params: params
  })
}

export function getLgActivity(params) {
  return request({
    url: base + 'api/jdActivity/lg/activity/page',
    method: 'get',
    params: params
  })
}

export function getMxActivityOrder(params) {
  return request({
    url: base + 'api/jdActivityOrder/mx/page',
    method: 'get',
    params: params
  })
}

export function getLgActivityOrder(params) {
  return request({
    url: base + 'api/jdActivityOrder/lg/page',
    method: 'get',
    params: params
  })
}

export function exportActivityOrder(params) {
  return request({
    url: base + 'api/jdActivityOrder/export',
    method: 'get',
    params: params
  })
}