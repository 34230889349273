<template>
  <div>
      <el-table
      :data="tableData"
      border
      style="width: 100%" v-loading="loading" height="90%">

       <el-table-column
        prop="skuid"
        label="商品ID">
      </el-table-column>

      <el-table-column
        prop="orderid"
        label="订单号">
      </el-table-column>

      <el-table-column
        prop="parentid"
        label="父单号">
      </el-table-column>

 <el-table-column
        prop="goodName"
        label="商品名称">
      </el-table-column>

      <el-table-column
        prop="shopName"
        label="店铺名称">
      </el-table-column>


      <el-table-column
        prop="status"
        label="订单状态">
      </el-table-column>
       <el-table-column
        label="订单时间"
        >
        <template slot-scope='scope'>
         <p>下单时间:{{scope.row.payAt}}</p>
         <p>完成时间:{{scope.row.finishAt}}</p>
        </template>
      </el-table-column>  
      <el-table-column
        prop="createdOn"
        label="更新时间">
      </el-table-column>
      <el-table-column
        prop="rewardStatus"
        label="奖励状态">
      </el-table-column>

      <el-table-column
        prop="promotion"
        label="推广位ID">
      </el-table-column>

       <el-table-column
        prop="promotionName"
        label="推广位名称">
      </el-table-column>

   </el-table>
     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { getMxActivityOrder } from "@/api/activityorder";
import router from '@/router';

  export default {
    
    data() {
      return {
      activityId: '',
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      };
    },
    mounted(){
      this.activityId = this.$route.params.activityId
this.search(this.$route.params.activityId)
    },
    methods: {
      async search(val){
           this.loading = true;
        await getMxActivityOrder({activityId: this.activityId, pageNo: this.currentPage, pageSize: this.pageSize})
          .then((res) => {
            this.loading = false;
            if (res.code == 1000) {
      this.tableData = res.data.records;
      this.total = res.data.total;
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search(); // 重新搜索以更新分页数据
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search(); // 重新搜索以更新分页数据
    }
    }
  };
</script>

